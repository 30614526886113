window.Vue = require('vue');

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.component('formulario-cambiar-contrasena', require('../../componentes/sitio/FormularioCambiarContrasena.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = false;

if (document.getElementById("vue-formulario-cambiar-contrasena")) {
    const formulario_inicio = new Vue({
        el: '#vue-formulario-cambiar-contrasena',
    });
}

