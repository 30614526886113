<template>
    <div class="zp-fix-margin">
        <ul class="nav nav-pills mb-4" id="loginTabs" role="tablist">
            <li class="nav-item mr-3" role="presentation">
                <a class="login-modal-tab" href="javascript:void(0)">
                    Cambio de contraseña
                </a>
            </li>
        </ul>
        <form method="POST" :action="url_formulario_update" v-on:submit="sendFormReset" class="zp-errors-border-bottom">
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" name="token" v-model="token_password">
            <div class="form-group">
                <input type="hidden" name="originTab" value="change_password">
                <div class="zp-divisor-recuperar"></div>
                <div class="zp-texto-gris"></div>
            </div>
            <input type="password"
                v-model="$v.formReset.password.$model"
                :class="!$v.formReset.password.$anyError ? 'form-control': 'form-control zp-form-error'"
                name="password" placeholder="Nueva contraseña" autocomplete="password" required @keypress="keypress">
                <div class="zp-error-data" v-if="$v.formReset.password.$dirty">
                    <span v-if="!$v.formReset.password.required" class="zp-error-span">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        Campo necesario
                    </span>
                    <span v-if="!$v.formReset.password.minLength && $v.formReset.password.required" class="zp-error-span">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        Al menos 8 caracteres
                    </span>
                </div>
            <input type="password"
                v-model="$v.formReset.password_confirmation.$model"
                :class="!$v.formReset.password_confirmation.$anyError ? 'form-control': 'form-control zp-form-error'"
                name="password_confirmation" placeholder="Confirmar contraseña" autocomplete="password_confirmation" required @keypress="keypress">
                <div class="zp-error-data" v-if="$v.formReset.password_confirmation.$dirty">
                    <span v-if="!$v.formReset.password_confirmation.required" class="zp-error-span">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        Campo necesario
                    </span>
                    <span v-if="!$v.formReset.password_confirmation.sameAsPassword && $v.formReset.password_confirmation.required" class="zp-error-span">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        Las contraseñas no son iguales
                    </span>
                </div>
                <div v-if="showErrorsBackend" :class="class_container_message_type">
                    <div v-for="error in inputs_errors_cleaned">
                        <div :class="class_message_type">
                            <i class="fas fa-exclamation-circle text-danger" v-if="message_type != 'ok'"></i>
                            {{error[0]}}
                        </div>
                    </div>
                </div>
            <div class="zp-divisor-recuperar"></div>
            <button type="submit" class="btn bg-main-color general-btn"
                :disabled="$v.formReset.$invalid">
                {{btnText}}
            </button>
        </form>
    </div>
</template>

<script>
    import { required, minLength, between, sameAs, email } from 'vuelidate/lib/validators'

    export default {
        name: "FormularioCambiarContrasena",
        props: [
            "url_formulario",
            "inputs_old",
            "inputs_errors",
            "inputs_errors_emails",
            "token_password",
            "url_formulario_update"
        ],
        data: function() {
            return  {
                tab_type:"forget",
                inputs_errors_cleaned:[],
                message_type:"bad",
                class_message_type:"zp-error-data",
                class_container_message_type:"zp-error-bag",
                formLoading: false,
                showErrorsBackend:false,
                btnText:"Enviar",
                formReset:{
                    //email:"",
                    password:"",
                    password_confirmation:"",
                },
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        validations: {
            formReset:{
                password:{
                    required,
                    minLength: minLength(8)
                },
                password_confirmation:{
                    required,
                    sameAsPassword: sameAs('password')
                },
            },
        },
        watch:{
        },
        mounted() {

            if(this.inputs_old){
                if(this.inputs_old.originTab == "change_password"){
                    let self = this;
                    Object.entries(this.inputs_errors).forEach( function(valor, indice, array){
                        if(valor[0] != "message_type"){
                            self.inputs_errors_cleaned.push(valor[1]);
                        }else{
                            self.message_type = valor[1];
                            if(self.message_type == "ok"){
                                self.class_message_type="zp-ok-data";
                                self.class_container_message_type="alert alert-success";
                            }
                        }
                    });
                    $('#passwordModal').modal('show');
                    $('.zp-auth-forget').hide();
                    $('.zp-auth-change_password').show();
                    this.showErrorsBackend = true;
                }
            }

            if(this.token_password != '0'){
                this.tab_type = "reset";
                $('#passwordModal').modal('show');
                $('.zp-auth-forget').hide();
                $('.zp-auth-change_password').show();
                this.showErrorsBackend = true;
            }

            let self = this;
            document.addEventListener('evento-cerrar-modales', function (e) {
                self.$v.$reset();
                self.showErrorsBackend = false;
            }, false);
        },
        methods:{
            keypress(){
                this.showErrorsBackend = false;
            },
            sendFormReset (event) {
                if(!this.formLoading){
                    this.formLoading = true;
                    this.btnText = "Enviando...";
                    return true;
                }
                event.preventDefault();
                return false;
            },
            sendForm (event) {
                if(!this.formLoading){
                    this.formLoading = true;
                    this.btnText = "Enviando...";
                    return true;
                }
                event.preventDefault();
                return false;
            },
            onlyNumber ($event) {
               let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
               if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                  $event.preventDefault();
               }
            },
            onlyLetters ($event) {
               var regex = new RegExp("^[a-zA-Z ]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    }
</script>

<style>

</style>
